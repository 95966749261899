'use strict';

angular.module('cpformplastApp.accounts')
  .controller('AccountsCreationController', function ($scope, appConfig, Auth, $state, Modal, Notification) {
    $scope.errors = {};
    $scope.roles = [];
    $scope.user = {};
    $scope.submitted = false;

    $scope.init = function() {
      $scope.roles = appConfig.userRoles;

      if ($state.params.userId) {
        Auth.getUser($state.params.userId).then(function(user){
          $scope.user = user;
        },function(error){
          console.log(error);
        });
      }

    };

    $scope.submitAccount = function(form, create) {

      $scope.submitted = true;

      if (!form.name.$valid || !form.username.$valid || !$scope.user.role || (!form.password.$valid && !$scope.user._id) ) {
        return false;
      }

      var promise ;
      if (!$scope.user._id) {
        promise = Auth.createUser({
          name: $scope.user.name,
          username: $scope.user.username,
          role: $scope.user.role,
          password: $scope.user.password
        });
      } else {
        promise = Auth.updateUser({
          _id: $scope.user._id,
          name: $scope.user.name,
          username: $scope.user.username,
          role: $scope.user.role
        });
      }

      promise.then((data) => {
        if (data !== undefined) {
          $scope.user._id = data._id;
          $state.params.userId = data._id;
        }
        Notification.success('Le compte <strong>' + $scope.user.username + '</strong> fut enregistré');
        if (create) {
          $state.go('accounts/account-creation',{'userId' : ''});
        }
      })
      .catch(err => {
        err = err.data;
        $scope.errors = {};
        Notification.error('Un probème est survenu');
        angular.forEach(err.errors, (error, field) => {
          form[field].$setValidity('mongoose', false);
          $scope.errors[field] = error.message;
        });
      });

    };

    $scope.delete = Modal.confirm.delete(res => {
      $scope.deleteUser();
		});

    $scope.deleteUser = function() {
      Auth.deleteUser($scope.user._id).then(() => {
        Notification.success('Le compte <strong>' + $scope.user.username + '</strong> fut supprimé avec succès');
        $state.go('accounts/accounts-management');
      })
      .catch(err => {
        Notification.error("Le compte n'a pas pu être supprimé");
      });
    };

    $scope.init();
  });
